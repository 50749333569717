.navbar-component {
  float: left;
  & nav {
    width: 4rem;
    height: calc(100vh - 6rem);
    background-color: #fcff5dea;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0rem;
    font-weight: 500;
  }
}
