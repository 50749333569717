.panel-component {
  background-color: yellow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 50%;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  .panel-component {
    width: 100%;
  }
  .rightPanel {
    animation: fadeOut 0.3s ease-in-out;
    width: 0;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      width: 100%;
    }
    to {
      opacity: 0;
      width: 0%;
    }
  }
}

.rightPanel {
  background-color: rgb(19, 59, 95);
}

iframe {
  width: 100%;
  height: 100%;
}

.header-qraneos {
  width: 100%;
  height: 2rem;
  background-color: #2c2e30;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;

  & div {
    width: 1.3rem;
    height: 1.3rem;
    user-select: none;
    background-color: red;
    color: white;
    float: right;
    border-radius: 0.3rem;
    text-align: center;
    font-weight: 500;
  }
}
