.personal-panel {
  gap: 1vw;
  & div {
    display: flex;
    gap: 1vw;
  }
  & img {
    border-radius: 50%;
    overflow: hidden;
    width: 4vw;
    height: 4vw;
  }
  & .perfil {
    width: 25vw;
    height: 25vw;
    overflow: hidden;
  }

  & span {
    font-size: 3vw;
    font-family: "Madimi One", sans-serif;
    font-style: italic;
  }

  & section img {
    width: 2vw;
    height: 2vw;
    object-fit: contain;
    border-radius: 0px;
  }
}
