.panel-PanelOne {
  background-color: yellow;

  & span {
    font-size: 4vw;
    font-family: "Madimi One", sans-serif;
  }

  @media screen and (max-width: 600px) {
    & span {
      font-size: 7vw;
    }
    & > span :first-child {
      font-size: 8.5vw !important;
    }
  }

  & > span :first-child {
    font-family: "Londrina Outline", sans-serif;
    font-size: 4.5vw;
    cursor: pointer;
  }

  & article {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
}
